.file-manager {
  &__bar {
    display: flex;
    align-content: stretch;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9eff4;

    button {
      margin-left: 10px;
    }

    .bar {
      &__breadcrumb {
        width: 40%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        direction: rtl;
        flex-grow: 1;
        color: #a9a9a9;
        font-size: 12px;
        font-weight: 300;

        ul {
          margin: 0;
          margin-top: 15px;
          padding: 0;

          li {
            display: inline;

            &::after {
              padding: 0px 10px;
              content: "/";
              color: #e3e3e3;
            }

            &:last-child::after {
              content: "";
            }

            a {
              padding: 5px;
            }
          }
        }
      }
    }
  }

  &__list {
    width: 100%;
    margin-bottom: 15px;
    float: left;

    .file-item {
      margin: 5px;
      float: left;
      width: 130px;
      height: 130px;
      text-align: center;
      border: 1px solid #e9eff4;
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
      user-select: none;
      position: relative;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      &:hover {
        -webkit-box-shadow: 0px 0px 0px 4px rgba(230, 246, 252, 1);
        -moz-box-shadow: 0px 0px 0px 4px rgba(230, 246, 252, 1);
        box-shadow: 0px 0px 0px 4px rgba(230, 246, 252, 1);
        border: 1px solid #57bfeb;
        border-radius: 5px;
        .file-item__delete-btn {
          display: inline;
        }
      }

      &--selected {
        background: rgba(230, 246, 252, 0.3);
        border: 1px solid #2eafe6;
        border-radius: 5px;
      }

      &__image {
        height: 64px;
        margin-top: 10px;
      }

      &__name {
        display: block;
        font-size: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 15px;
      }

      &__delete-btn {
        display: none;
        border: 0;
        width: 22px;
        height: 22px;
        position: absolute;
        right: 7px;
        top: 7px;
        padding: 0;
        background: transparent;

        &:before {
          background-image: url("../../../assets/icons/delete_ico.svg");
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          position: absolute;
          content: "";
          background-size: 16px 17px;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
