$color_alto_approx: #d9d9d9;
$white: #fff;
$color_razzmatazz_approx: #e6175c;
$color_mantis_approx: #70b95c;

$url_0: url(../../assets/icons/notification-success.svg);
$url_1: url(../../assets/icons/notification-error.svg);

.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
}
@keyframes alert {
	0% {
		transform: scaleX(1);
	}

	10%,
	20% {
		transform: scale3d(0.9, 0.9, 0.9) rotate(-1deg);
	}

	30%,
	50%,
	70%,
	90% {
		transform: scale3d(1.02, 1.02, 1.02) rotate(1deg);
	}

	40%,
	60%,
	80% {
		transform: scale3d(1.02, 1.02, 1.02) rotate(-1deg);
	}

	to {
		transform: scaleX(1);
	}
}
.alert {
	animation-name: alert;
	&.success:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 15px;
		width: 30px;
		height: 30px;
		transform: translate(0%, -50%);
		background-repeat: no-repeat;
		background-size: contain;
		background: $url_0;
	}
	&.failure:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 15px;
		width: 30px;
		height: 30px;
		transform: translate(0%, -50%);
		background-repeat: no-repeat;
		background-size: contain;
		background: $url_1;
	}
}
.notification {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1004;
	padding: 20px 10px;
	width: auto;

	.alert {
		height: 50px;
		padding: 0.625em;
		box-shadow: 0 0 0.625em $color_alto_approx;
		line-height: 27px;
		font-weight: 400;
		background-color: $white;
		padding-left: 55px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&.failure {
			color: $color_razzmatazz_approx;
		}
		&.success {
			color: $color_mantis_approx;
		}
		.transparent_button {
			color: #2f353c;
			font-size: 1.5rem;
			line-height: 21px;
			font-weight: 400;
			padding-right: 10px;
			outline: none;

			&:hover {
				outline: none;
				text-decoration: none;
			}
			&:focus {
				outline: none;
			}
		}
	}
}
