.miniFile {
	position: relative;
	display: block;
	width: 100%;
	float: left;
	border: 1px solid #E9EFF4;
    border-radius: 5px;
	padding: 10px 20px;
	margin: 5px 0px;
	
	&:hover{
		-webkit-box-shadow: 0px 0px 0px 4px rgba(230,246,252,1);
        -moz-box-shadow: 0px 0px 0px 4px rgba(230,246,252,1);
        box-shadow: 0px 0px 0px 4px rgba(230,246,252,1);
        border: 1px solid #57BFEB;
        border-radius: 5px;
	}

	img {
		margin-right: 20px;
	}
	button {
		margin-left: 10px;
		float: right;
		display: inline-block;
	}
	a.linkFile {
		float: left;
	}

	&.noHover{
		border: 1px solid #E9EFF4;
    	border-radius: 5px;
		box-shadow: none;

		a.linkFile:hover {
			text-decoration: none;
			cursor: initial;
		}
	}
}
.files {
	margin-bottom: 20px;
	
	.file {
		a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		img {
			display: block;
			margin: 0 auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -80%);
		}
		span {
			display: block;
			margin: 0 auto;
			position: absolute;
			bottom: 15%;
			width: 100%;
			font-size: 13px;
		}
	}
}