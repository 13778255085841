#sidebar {
  float: left;
  width: 110px;
  z-index: 1001;
  background: #2f353c;
  box-shadow: 0 0 20px 0 rgba(202, 202, 202, 0.5);
  position: fixed;
  padding-top: 0px;
  height: 100%;
  transition: 0.05s ease-in;

  .brand {
    width: 100%;
    height: 90px;
    text-align: center;

    img {
      max-width: 40px;
      height: auto;
    }
  }

  nav {
    text-align: center;
    margin-top: 40px;
  }

  .logout {
    position: absolute;
    bottom: 30px;
    width: 100%;
  }

  a {
    font-size: 10px;
    font-weight: 300;
    color: #93969a;
  }

  .nav-link {
    display: block;
    padding: 10px 0;
    position: relative;

    &.active {
      color: #fff;
      font-weight: 700;

      &::before {
        content: "";
        width: 6px;
        height: 40%;
        background: #00b3ed;
        box-shadow: 2px 3px 11px 0 rgba(1, 172, 253, 0.74);
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-60%);
        border-radius: 0px 6px 6px 0px;
      }

      .icon {
        color: #2dafe6;
      }
    }

    &::hover {
      color: #fff;

      .icon {
        color: #2dafe6;
      }
    }
  }

  .icon {
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
  }
}
