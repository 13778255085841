.overlay_modal{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: none;

    .popup{
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        border: none;
        background: rgb(255, 255, 255);
        overflow: auto;
        border-radius: 5px;
        outline: none;
        padding: 40px;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        max-width: 630px;
        text-align: center;

        img{
            max-width: 70px;
        }

        button{
            margin: 0px 5px;
        }

        h3{
            margin-bottom: 10px;
            margin-top: 25px;

            &:after{
                display: none;
            }
        }
    }

    &.show{
        display: block;
    }
}

.modal-dialog { max-width: 730px; margin: 30px auto; background-color: #fff; }
.modal-show {
    h1 { font-size: 24px; margin-bottom: 10px; &:after {background-color: #fff;} }
    h2 { font-size: 16px; margin-bottom: 30px; &:after {background-color: #fff;} }
    h3 { font-size: 14px; margin-bottom: 5px; &:after {background-color: #fff;}}
    p { font-size: 13px; }
}
.modal-backdrop.show { opacity: 1; }
.modal-backdrop { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1040; background-color: #edf1f5; }
.modal-content {border: none;border-radius: 0;outline: 0;}