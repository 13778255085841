
body {
	background-color: #edf1f5 !important;
}

.FormWrapper {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
    margin-left: auto;
}

@media (min-width:576px) {
	.FormWrapper {
		max-width: 540px;
	}

}

@media (min-width:768px) {
	.FormWrapper {
		max-width: 720px;
	}

}

@media (min-width:992px) {
	.FormWrapper {
		max-width: 960px;
	}

}

@media (min-width:1200px) {
	.FormWrapper {
		max-width: 1140px;
	}

}
