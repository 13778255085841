@import "./fonts.scss";
@import "react-datepicker/dist/react-datepicker.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// MAIN STYLES

/* -- Primary style ---- */
*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  word-wrap: break-word;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
}

body {
  color: #2f353c;
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
body,
html {
  overflow-x: hidden;
}
section {
  float: left;
  width: 100%;
}

#wrapper {
  height: 100%;
}

#main {
  float: right;
  width: calc(100% - 110px);
  background: #edf1f5;
  min-height: 100vh;

  .content {
    margin-top: 90px;
    font-size: 13px;
  }
}
.content {
  font-size: 14px;
}

footer {
  width: 100%;
  float: left;
}

a {
  color: #2f272a;
  text-decoration: none;
  transition: color 0.2s ease-out;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #2f272a;
  }
}

p {
  line-height: 1.5;
  font-size: 14px;
  color: #2f353c;
}

strong {
  color: #21252b;
  font-weight: 600;
}

hr {
  border-top: 1px solid #ededed;
  margin: 30px 0px;
}

button {
  cursor: pointer;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #2f353c;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
}

.variace label {
  color: inherit;
  font-size: inherit;
  text-transform: inherit;
  font-weight: inherit;
}

.table tr:first-child {
  td,
  th {
    border-top: 1px solid transparent;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 10px;
  font-size: 13px;
  color: #323c47;
  line-height: 1.5;
  color: #21252b;
  background: #ffffff;
  border: 1px solid #e9eff4;
  border-radius: 4px;
  background-image: none;
  background-clip: padding-box;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-header-field {
  height: 40px !important;
}

.search-header {
  padding-left: 8px !important;
}

.form-group {
  input[type="checkbox"] {
    display: inherit;
    width: auto;
    float: left;
  }
}

select {
  height: auto;
}

select::-ms-expand {
  display: none;
}

.form-group {
  margin-bottom: 25px;
}

h1,
h2,
h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #2f353c;
  position: relative;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: inherit;
}

h1 {
  font-size: 24px;
  margin-bottom: 40px;
  &:after {
    content: "";
    width: 20px;
    height: 1px;
    background: #2dafe6;
    position: absolute;
    left: 0;
    bottom: -8px;
  }
}

h3 {
  font-size: 18px;
  margin-bottom: 30px;
  &:after {
    content: "";
    width: 20px;
    height: 1px;
    background: #2dafe6;
    position: absolute;
    left: 0;
    bottom: -8px;
  }
}

.h3-reclamaction {
  font-size: 18px;
  margin-bottom: 20px;
  &:after {
    background: none;
  }
}

h4 {
  margin-bottom: 0.5rem;
  color: #2f353c;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
}

ul {
  list-style: none;
}

.table-hover tbody tr:hover {
  background-color: #f8fcff;
}

.is-invalid {
  .invalid-feedback {
    display: block;
  }
}

.warning {
  font-weight: 700;
  color: red;
  margin-left: 10px;
}

.ok {
  font-weight: 700;
  color: green;
  margin-left: 10px;
}

.notification {
  position: fixed;
  top: 120px;
  right: 15px;
  z-index: 1004;
  width: 300px;
}

.alert {
  position: relative;
  padding: 15px 40px 15px 15px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.form {
  position: relative;
  z-index: 1;
  margin: 100px auto 100px;
  padding: 45px;
  text-align: left;
  background: #ffffff;
  border: 1px solid #e9eff4;
  box-shadow: 0 0 40px 0 #f2f2f2;
}

.btn {
  border-radius: 6;
  border: none;
  padding: 13px 20px;
  font-size: 11px;
  font-weight: 700;
}

.btn-primary {
  background: #2dafe6;
}

.btn-primary:hover {
  color: #fff;
  background-color: #238ebb;
  box-shadow: none;
}
.reveal-if-active {
  display: none;
  margin-top: 25px;
  border-top: 1px solid #e9eff4;
  padding-top: 20px;
}

.buttons-line {
  .btn {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.form-group {
  input[type="checkbox"] {
    display: inherit;
    width: auto;
    float: left;
    margin-right: 4px;
    margin-top: 4px;
  }
}
.hidden {
  visibility: hidden;
  margin-bottom: -25px;
}

.thumbnail {
  max-width: 70px;
}

.attribute-image-field {
  margin: 10px 0;
}

.thumbnail-form {
  width: 50px;
  margin-right: 10px;
}

.custom-control-label {
  &:before,
  &:after {
    top: 0;
  }
}

.attribute-images {
  display: flex;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}

.loader {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 50px;
  display: inline-block;
}
.description {
  margin-bottom: 20px;
}

.summary {
  text-align: center;
  margin-top: 25px;
}

.form-wrapper {
  margin: 0 0;
}

.btn-font {
  font-size: 16px;
}

.small-note {
  color: #93969a;
  margin-top: 25px;
}

.contacts {
  float: left;
  width: 47% !important;
  margin-right: 10px;
}

.seperators {
  border-bottom: 1px solid #e2e4e7;
}

.row-spacing {
  height: 40px;
  margin: 20px 0px 20px 0px;
}

.row-text-area-spacing {
  height: auto;
  margin: 20px 0px 20px 0px;
}

.span-header {
  text-align: center;
  display: block;
  font-weight: 800;
}

.span-text {
  text-align: center;
  display: block;
  margin: 25px 0 25px 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-label {
  margin-top: 3px;
  margin-left: 38px;
}

.onoffswitch {
  margin-top: 20px;
}

.disable-link {
  pointer-events: none;
  cursor: default;
}

.orange-text {
  color: #ffc107;
  font-weight: 700;
}

.red-text {
  color: #dc3545;
  font-weight: 700;
}

.green-text {
  color: #00ff43;
  font-weight: 700;
}

.narrow-col {
  width: 80px;
}

select.form-control:not([size]):not([multiple]) {
  height: auto;
}

.mb-30 {
  margin-bottom: 30px;
}

.submittedInfo {
  text-align: center;

  img {
    max-width: 75px;
    margin-bottom: 30px;
  }
}
