.table {
  .table_buttons {
    min-width: 234px;

    @media only screen and (max-width: 923px) {
      min-width: 150px;
    }
  }

  .blueTableHeader {
    font-size: 17px;
    color: #2dafe6;
  }
  th,
  td {
    color: #666;
    font-size: 12px;
    border-bottom: solid 1px #eaedef;
  }

  &.thin_rows {
    th,
    td {
      padding: 3px 7px;
    }
  }

  td {
    vertical-align: middle;
  }

  tr:first-child td,
  tr:first-child th {
    border-top: 1px solid transparent;
  }

  tr {
    button {
      opacity: 0.6;
    }
  }

  &.table-hover {
    tbody tr:hover {
      background-color: #fafbfc;
      button {
        opacity: 1;
      }
    }
  }

  .state {
    font-weight: 700;

    &--completed {
      color: green;
    }

    &--progress {
      color: orange;
    }

    &--notcompleted {
      color: red;
    }
  }

  &__buttons {
    min-width: 130px;
  }

  .action_button {
    margin: 0px;
  }

  .search-header {
    width: auto;
    min-width: 14%;

    select {
      width: 100%;
    }
  }
}

#filterArea {
  background: #ffffff;
  border: 1px solid #e9eff5;
  border-top: 0px;
  border-radius: 4px;
  padding: 0px 20px;
}
#filterArea input {
  border: none;
  height: auto;
  padding: 17px 10px;
  box-shadow: none;
}

#filterArea .filterOptions {
  font-weight: 700;
  border-left: 1px solid #e9eff5;
  padding: 17px 10px;
  font-size: 12px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

#filterArea .filterOptions div {
  font-weight: 300;
  margin-left: 35px;
  display: inline;
  position: relative;
  cursor: pointer;
}

#filterArea .filterOptions .filterItem span {
  position: absolute;
  width: 14px;
  height: 14px;
  left: -20px;
  top: 50%;
  background: #ffffff;
  border: 1px solid #dce0e1;
  transform: translate(0%, -50%);
}

#filterArea .filterOptions .filterItem.active span:after {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  left: 50%;
  top: 50%;
  background: #2bafe6;
  transform: translate(-50%, -50%);
}
