$dropDown: url(../../assets/drop-down.png);

.select-input {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    top: 50%;
    right: 15px;
    background-image: $dropDown;
    background-repeat: no-repeat;
    pointer-events: none;
    background-size: contain;
    transform: translate(0%, -50%);
  }
}

.is-disabled {
  .select-input {
    width: max-content;
    &:after {
      display: none;
    }
  }
}
