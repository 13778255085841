$dateIcon: url(../../assets/calendar.svg);
$timeIcon: url(../../assets/clock.svg);

.select-input {
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
    }
  }
}

.select-input.select-input-time {
  &:after {
    background-image: $timeIcon;
  }
}

.select-input.select-input-date {
  &:after {
    background-image: $dateIcon;
  }
}
