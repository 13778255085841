@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&subset=latin-ext");

@font-face {
  font-family: "bafire";
  src: url(./fonts/bafire.eot?28e6hk);
  src: url(./fonts/bafire.eot?28e6hk#iefix) format("embedded-opentype"),
    url(./fonts/bafire.ttf?28e6hk) format("truetype"), url(./fonts/bafire.woff?28e6hk) format("woff"),
    url(./fonts/bafire.svg?28e6hk#bafire) format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "bafire" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-accept:before {
  content: "\e900";
}
.icon-attribute:before {
  content: "\e901";
}
.icon-configure:before {
  content: "\e902";
}
.icon-delete:before {
  content: "\e903";
}
.icon-edit:before {
  content: "\e904";
}
.icon-file:before {
  content: "\e905";
}
.icon-folder:before {
  content: "\e906";
}
.icon-help:before {
  content: "\e907";
}
.icon-next:before {
  content: "\e908";
}
.icon-odhlasit:before {
  content: "\e909";
}
.icon-overview:before {
  content: "\e90a";
}
.icon-plus:before {
  content: "\e90b";
}
.icon-residence:before {
  content: "\e90c";
}
.icon-home:before {
  content: "\e90e";
}
.icon-archive:before {
  content: "\e90d";
}
