button {
  transition: color 0.2s ease-out;

  &:disabled {
    background-color: #edf1f5 !important;
    color: #2f353c !important;
    cursor: not-allowed;
  }
}

.action_button {
  background-color: red;
  padding: 0px 6px;
  display: inline-block;
  line-height: 19px;
  font-size: 10px;
  text-transform: uppercase;
  background: #edf1f5;
  text-decoration: none;
  border-radius: 6px;
  font-size: 10px;
  border: none;
  margin: 7px 10px 7px 0px;

  &:hover {
    background: #2dafe6;
    color: #fff;
    text-decoration: none;
  }
}
.tab_button {
  font-size: 11px;
  font-weight: 300;
}
.transparent_button {
  background: transparent;
  outline: none;
  border: none;
  font-size: 11px;
  font-weight: 300;
  margin: 0 15px;
  border: none;

  &:hover {
    text-decoration: underline;
    outline: none;
  }
}
.trash_button {
  background-image: url("../../assets/icons/delete_ico.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  background-size: 16px 17px;
  background-color: transparent;
  border: 0px;

  &:hover {
    opacity: 0.8;
  }
}

.icon_button {
  font-size: 10px;
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 10px 10px;
  background-color: transparent;
  border: 1px solid #edf1f5;
  border-radius: 2px;
  padding: 5px 5px 5px 24px;
  margin-right: 4px;
  color: #514d50;

  &:hover {
    color: #2f353c;
    border-color: #00b1ec;
  }
}
.delete_button {
  color: #eb5f5f;
  background-image: url("../../assets/icons/delete_ico.svg");

  &:hover {
    color: #eb5f5f;
    border-color: #eb5f5f;
  }
}
.archive_button {
  color: #eb5f5f;
  background-image: url("../../assets/icons/ico-archive.svg");

  &:hover {
    color: #eb5f5f;
    border-color: #eb5f5f;
  }
}
.edit_button {
  background-image: url("../../assets/icons/edit_ico.svg");
}
.email_button {
  background-image: url("../../assets/icons/email_icon.svg");
}
.detail_button {
  background-image: url("../../assets/icons/detail_ico.svg");
}
.reset_button {
  background-image: url("../../assets/icons/reset_ico.svg");
}
.stage_button {
  background-image: url("../../assets/icons/stage_ico.svg");
}
.btn-primary:focus {
  background: #2dafe6;
  color: #fff;
  text-decoration: none;
}

.button-gray {
  background-color: #edf1f5;
  color: #2f353c;
}

.blue_link {
  color: #00b1ec;
}
