.dropzone {
  position: relative;
  width: 100%;
  height: auto;
  border-width: 1px;
  border-color: #2eafe6;
  border-style: dashed;
  border-radius: 5px;
  text-align: center;
  padding: 40px;
  color: #2eafe6;
  margin-bottom: 20px;
}
