.navigace {
    min-height: 90px;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(202, 202, 202, 0.5);
    margin-left: 110px;
    z-index: 1000;
    h1 {
      margin-bottom: 0;
      span {
        font-weight: 300;
      }
      &:after {
        display: none;
      }
    }
    p.clientInfo {
      margin-bottom: 0;
    }
    .btn{
      margin-left: 12px;
    }
    .groupButtons{
        button{
          margin-left: 0px;
        }
    }
  }