.tab-pane.active.show {
  position: relative;
  left: 0px;
  animation: fadein 0.25s;
  -moz-animation: fadein 0.25s; /* Firefox */
  -webkit-animation: fadein 0.25s; /* Safari and Chrome */
  -o-animation: fadein 0.25s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
    left: -44px;
  }
  to {
    opacity: 1;
    left: 0px;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
    left: -44px;
  }
  to {
    opacity: 1;
    left: 0px;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
    left: -44px;
  }
  to {
    opacity: 1;
    left: 0px;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
    left: -44px;
  }
  to {
    opacity: 1;
    left: 0px;
  }
}
