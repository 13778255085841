.block {
    background: #fff;
    padding: 25px;
    float: left;
    width: 100%;
    margin: 20px 0px;
    box-shadow: 0 2px 9px 0 rgba(0,0,0,0.03);

    &__login {
        position: relative;
        z-index: 1;
        margin: 100px auto 100px;
        padding: 45px;
        text-align: left;
        background: #FFFFFF;
        border: 1px solid #E9EFF4;
        box-shadow: 0 0 40px 0 #F2F2F2;
    }
}